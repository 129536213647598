<template>
	<div class="study-detail">
		<w-navTab titleText="详情"></w-navTab>
		<div class="container">
			<div class="head-nav">
				<div class="head-title">{{ studyDetail.title }}</div>
				<div class="head-bottom">
					<div class="head-bottom-item">
						<div class="text">时间: {{ studyDetail.create_time }}</div>
					</div>
					<div class="head-bottom-item">
						<div class="text">点赞: {{ studyDetail.approval }}</div>
						<div class="text">阅读: {{ studyDetail.views }}</div>
					</div>
				</div>
			</div>
			
			<div class="content">
				<div class="content-bottom-picture"><video class="detail-video" autoplay :src="studyDetail.video" controls></video></div>
			</div>
			
			<div class="footer">
				<!-- 点赞 -->
				<div class="footer-item" @click="onPraise" v-show="!isPraise">
					<div class="picture"><img src="@/assets/img/wz1.png" /></div>
				</div>
				<div class="footer-item footer-item-like" @click="unPraise" v-show="isPraise">
					<div class="picture"><img src="@/assets/img/wz11.png" /></div>
				</div>
				<!-- 分享 -->
				<div class="footer-item footer-item-share" @click="onshare()">
					<div class="picture"><img src="@/assets/img/wz33.png" /></div>
				</div>
				<Share :loading="isShowShare" @Touch="share()"></Share>
			</div>
		</div>
	</div>
</template>
<script>
import StudyApi from '@/api/study';
import News from '@/api/news';
import Vant from '@/vendor/vant';
import Share from '@/components/Share';
export default {
	name: 'StudyDetail',
	data() {
		return {
			isPraise: '',
			isCollect: '',
			studyDetail: '',
			pageId: '',
			typeId: 6,
			isShowShare: false
		};
	},
	created() {
		this.pageId = this.$route.params.id;
		this.onDetail();
			
		wx.onMenuShareTimeline({
			title: this.studyDetail.title,
			link: window.Location.href,
			imgUrl: 'http://xzyjv2.zyrlzy.cn/static/api/img/share_logo.jpg',
			success: () => {
				this.isShowShare = false;
				Vant.Toast.success('分享成功');
			},
			error: () => {
				console.log(12)
			}
		});
		
		wx.onMenuShareAppMessage({
			title: this.studyDetail.title,
			link: window.Location.href,
			imgUrl: 'http://xzyjv2.zyrlzy.cn/static/api/img/share_logo.jpg',
			success: () => {
				this.isShowShare = false;
				Vant.Toast.success('分享成功');
			},
			error: () => {
				console.log(12)
			}
		});
		
		News.isPraise({
			page_id: this.pageId,
			type_id: 6
		})
			.then(result => {
				this.isPraise = result.data.is_praise;
			})
			.catch(error => {});
	},
	methods: {
		onDetail() {
			StudyApi.studyDetail({ study_id: this.$route.params.id })
				.then(result => {
					this.studyDetail = result.data;
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 点赞
		onPraise() {
			let params = {
				page_id: this.pageId,
				type_id: this.typeId
			};
			News.praise(params)
				.then(result => {
					this.isPraise = true;
					Vant.Toast.success('点赞成功');
					this.studyDetail.approval++
				})
				.catch(error => {
					Vant.Toast(error.msg)
				});
		},
		// 取消点赞
		unPraise() {
			let params = {
				page_id: this.pageId,
				type_id: this.typeId
			};
			News.unPraise(params)
				.then(result => {
					this.isPraise = false;
					Vant.Toast('取消点赞成功');
					this.studyDetail.approval--
				})
				.catch(error => {
					Vant.Toast('取消点赞失败');
				});
		},
		// 分享
		onshare() {
			this.isShowShare = true
		},
		share(){
			this.isShowShare = false
		}
	},
	components:{
		Share
	}
};
</script>
<style scoped lang="less">
.study-detail {
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
	.container{
		padding-top: 20px;
		box-sizing: border-box;
		.head-nav {
			margin: 20px;
			margin-top: 0;
			border-bottom: 1px solid #ddd;
			padding-bottom: 2px;
		
			.head-title {
				font-size: 18px;
				line-height: 26px;
				color: #222;
				margin-bottom: 5px;
				font-weight: bold;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		
			.head-bottom {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.head-bottom-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.text {
						font-size: 12px;
						line-height: 26px;
						color: #777;
						margin-right: 15px;
					}
					.text:last-child {
						margin-right: 0;
					}
				}
			}
		}
		
		.content {
			margin: 0 20px;
			padding-bottom: 15px;
			border-bottom: 1px solid #ddd;
			.content-bottom-picture {
				width: 100%;
				height: 180px;
				video {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		.publ {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			color: #aaa;
			padding: 18px;
			box-sizing: border-box;
		}
		.footer {
			padding: 20px 80px 50px 80px;
			display: flex;
			justify-content: space-between;
		
			.footer-item {
				background-color: #ddd;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				color: #aaa;
				padding: 18px;
				box-sizing: border-box;
		
				.picture {
					width: 24px;
					height: 24px;
		
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		
			.footer-item-like {
				background: #ff6288;
				.publ();
			}
		
			.footer-item-collect {
				background: #ffb609;
				.publ();
			}
		
			.footer-item-share {
				background: #4399ff;
				.publ();
			}
		}
	}
}
</style>
